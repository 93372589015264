<template>
  <IconCard 
    class="md:col-span-3" 
    :ui="{
      background: 'bg-white/40 dark:bg-gray-900/50',
      ring: 'ring-1 dark:sm:ring-0 ring-primary-200 sm:ring-primary-200 dark:ring-gray-600'
    }"
    icon="i-heroicons-paper-airplane-20-solid"
    color="primary"
  >
    <template #header>
      <h3 class="text-sm sm:text-lg mb-0 text-primary-500 dark:text-primary-200">Blijf op de hoogte</h3>
      
    </template>
    <template #default>
      <p class="text-sm md:text-base dark:text-gray-300 leading-loose mb-8">Ontvang een maandelijkse rapportage met de meest recente statistieken in je email inbox.</p>
      <UForm 
        :validate="validate" 
        :state="state"
        class="flex gap-2 items-stretch w-full lg:max-w-[80%]" 
        @submit="onSubmit"
      >
        <UFormGroup 
          label="Email" 
          name="email" 
          size="xl" 
          :ui="{
            container: 'mt-0',
            label: {
              base: 'hidden'
            },
            error: 'text-xs'
          }" 
          class="grow"
        >
          <UInput 
            v-model="state.email" 
            icon="i-heroicons-envelope-16-solid" 
            placeholder="Jouw email" 
            color="primary"
            :loading="loading"
            :ui="{
              icon: {
                size: {
                  xl: 'h-4 w-4'
                }
              },
              form: 'h-[39px]',
              leading: {
                padding: {
                  xl: 'ps-10'
                }
              },
              size: {
                xl: 'text-sm'
              }
            }" />
        </UFormGroup>

        <UButton 
          type="submit" 
          color="primary" 
          variant="soft" 
          size="xl" 
          class="h-[39px] self-start"
          :ui="{
            variant: {
              soft: 'bg-primary-100 hover:bg-primary-200'
            }
          }"
          icon="i-heroicons-paper-airplane-16-solid"
        >
        <span class="hidden sm:inline md:hidden lg:inline">meld je aan</span>
        </UButton>
      </UForm>
    </template>
  </IconCard>
</template>

<script setup lang="ts">

import { z } from 'zod'
import type { FormError, FormErrorEvent, FormSubmitEvent } from '#ui/types'



const schema = z.object({
  email: z.string().email('Ongeldig emailadres...'),
})

const {listId} = useAppConfig().settings.loops.monthlyReport
const state: { email: string, mailingLists: string[] } = reactive({
  email: '',
  mailingLists: [listId]
})

const loading = ref(false)

const validate = (state: any): FormError[] => {
  const errors = []
  if (!state.email) errors.push({ path: 'email', message: 'Verplicht' })
  else {
    // Validate email using Zod schema
    const result = schema.safeParse({ email: state.email })
    if (!result.success) {
      errors.push({ path: 'email', message: 'Ongeldig emailadres' })
    }
  }
  return errors
}

const { $api } = useNuxtApp()

async function onSubmit() {
  loading.value = true

  const failed = {
      id: 'subscribe_failed',
      title: 'Er ging iets mis...',
      icon: 'i-heroicons-exclamation-triangle-20-solid',
      description: 'Probeer het opnieuw!',
      color: 'warn' as 'warn',
      timeout: 8000
  }
  const toast = useToast()

  try {
    await $api('/auth/subscriber/subscribe', {
      body: state,
      method: 'post'
    }).then((res: any) => {
      console.log(res)
      if (res.success) {
        toast.add({
          id: 'subscribed',
          title: 'Je bent ingeschreven!',
          icon: 'i-heroicons-envelope-20-solid',
          description: 'Je bent ingeschreven voor de rapportages. Houdt aan het begin  van de volgende kalendermaand je inbox in de gaten!',
          color: 'success',
          timeout: 12000
        })
      } else {
        toast.add(failed)
      }
      loading.value = false
    })
  } catch (err) {
    loading.value = false
    console.log(err)
    toast.add(failed)
  }
}

</script>